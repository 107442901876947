<template>
  <div class="page">
    <PageTitle title="用户列表"></PageTitle>
    <div class="content">
      <el-form inline :model="search" class="tb-form">
        <el-form-item label="用户名">
          <el-input v-model="search.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="add">
        <el-button class="tb-button" type="primary" @click="add">
          <i class="tb-icon-plus"/>新建用户
        </el-button>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          class="tb-table"
          @row-dblclick="setUser"
        >
          <!-- <el-table-column prop="id" label="序号"/> -->
          <el-table-column prop="user_name" label="用户名"/>
          <el-table-column prop="phone" label="手机号"/>
          <el-table-column prop="role_name" label="角色"/>
          <!-- <el-table-column prop="brand" label="品牌"/> -->
          <el-table-column prop="email" label="邮箱"/>
          <el-table-column prop="sms" label="验证码"/>
          <el-table-column prop="ip" label="ip变动检测"/>
          <el-table-column label="操作">
            <span slot-scope="scope" class="edit" @click="setUser(scope.row)">编辑</span>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          class="tb-pagination"
          layout="prev, pager, next"
          :current-page.sync="page.no + 1"
          :page-size="page.size"
          :total="page.total"
          @current-change="pageChange"
        />
      </div>
    </div>
    <el-dialog
      v-if="userVisible"
      :title="title"
      :visible.sync="userVisible"
      width="500px"
    >
      <el-form :model="user" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="用户名：" prop="user_name">
          <el-input v-model="user.user_name" placeholder="请输入用户名" style="width: 226px;" :disabled="!isNew"></el-input>
          <div class="tips">用户名一旦建立无法修改</div>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="user.phone" placeholder="请输入手机号" style="width: 226px;"></el-input>
        </el-form-item>
        <el-form-item label="email：" prop="email">
          <el-input v-model="user.email" placeholder="请输入邮箱" style="width: 226px;"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="pwd">
          <el-input v-model="user.pwd" style="width: 226px;" type="password" placeholder="请输入密码" show-password></el-input>
          <div class="tips">新建必填，编辑不填默认不修改</div>
        </el-form-item>
        <!-- <el-form-item label="设置品牌：">
          <el-select v-model="user.brand_id" placeholder="请选择品牌" filterable clearable>
            <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions" :key="item.id"></el-option>
          </el-select>
          <div class="tips">选择品牌后则设置为商家用户</div>
        </el-form-item> -->
        <el-form-item label="设置角色：" prop="role_id">
          <el-select v-model="user.role_id" placeholder="请选择角色" filterable clearable>
            <el-option :label="item.roleName" :value="item.id" v-for="item in roleOptions" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否验证手机号：" prop="is_verify_sms">
          <el-radio-group v-model="user.is_verify_sms">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否验证ip变动：" prop="is_ip_verify">
          <el-radio-group v-model="user.is_ip_verify">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import $md5 from 'js-md5'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'UserList',
  components: {
    PageTitle
  },
  data() {
    let phone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机'))
      } else if (!RegExp(this.config.mobile_regular, 'gi').test(value)) {
        callback(new Error('请输入正确手机格式'))
      } else {
        callback()
      }
    }
    let email = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'))
      } else if (!RegExp(this.config.email_regular, 'gi').test(value)) {
        callback(new Error('请输入正确邮箱格式'))
      } else {
        callback()
      }
    }
    let pwd = (rule, value, callback) => {
      if (this.isNew && value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      isNew: true,
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      },
      user: {
        user_name: '',
        phone: '',
        email: '',
        pwd: '',
        brand_id: '',
        role_id: '',
        is_verify_sms: '',
        is_ip_verify: ''
      },
      rules: {
        user_name: [{required: true, message: '请输入用户名'}],
        phone: [{required: true, validator: phone}],
        email: [{required: true, validator: email}],
        pwd: [{validator: pwd}],
        role_id: [{required: true, message: '请设置角色'}],
        is_verify_sms: [{required: true, message: '请选择是否需要验证码'}],
        is_ip_verify: [{required: true, message: '请选择是否验证ip变动'}]
      },
      userVisible: false,
      roleOptions: []
    }
  },
  computed: {
    title() {
      return this.isNew ? '新建用户' : '编辑用户'
    },
    ...mapState({
      brandOptions: 'brandOptions',
      config: 'config'
    })
  },
  async mounted() {
    await this.getRole()
    await this.getList()
  },
  methods: {
    async getRole() {
      const url = 'admin/role/list'
      const role = await this.$https.get(url)
      if (role) {
        this.roleOptions = role
      }
    },
    async getList() {
      let {no, size} = this.page
      const params = {
        name: this.search.name,
        page_size: size,
        page_no: no,
      }
      const url = 'admin/user/list'
      const data = await this.$https.get(url, {params})
      if (data) {
        data.list.forEach(item => {
          item.sms = item.is_verify_sms === 1 ? '是' : '否'
          item.ip = item.is_ip_verify === 1 ? '是' : '否'
          item.brand = ''
          if (item.brand_id) {
            const brand = this.brandOptions.find(brand => brand.id * 1 === item.brand_id * 1)
            if (brand) item.brand = brand.brandName
          }
        })
        this.page.total = data.total_count
        this.tableData = data.list
      }
    },
    add() {
      this.isNew = true
      this.user = {}
      this.userVisible = true
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    setUser(row) {
      this.isNew = false
      this.user = Object.assign(this.user, row)
      this.userVisible = true
    },
    async confirm() {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const {id, phone, user_name, email, pwd, is_ip_verify, is_verify_sms, brand_id, role_id} = this.user
      if (this.isNew && !pwd) {
        this.$message.error('请输入密码')
        return
      }
      const params = {
        user_name,
        phone,
        is_ip_verify,
        is_verify_sms,
        email,
        brand_id,
        role_id
      }
      if (pwd) params.pwd = $md5(pwd).toUpperCase()
      if (id) params.id = id
      const url = 'admin/user/edit'
      this.$https.post(url, params).then(res => {
        if (res) {
          this.$message.success(this.isNew ? '新建成功' : '编辑成功')
          this.userVisible = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
